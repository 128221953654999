<script setup lang="ts">
import Popper from 'vue3-popper';
import { useAttrs } from 'vue';

const attrs = useAttrs();

const openDelayMs = computed<number>(() => {
  const delay = attrs['open-delay'];
  return delay ? Number(delay) : 200;
});
</script>

<template>
  <Popper v-bind="attrs" :open-delay="openDelayMs" arrow class="cursor-pointer">
    <slot></slot>

    <template #content="props">
      <slot name="content-wrapper" v-bind="props">
        <div
          class="select-none text-center text-xs font-normal"
          :class="attrs.mw ?? 'max-w-[240px]'"
        >
          <slot name="content" v-bind="props"></slot>
        </div>
      </slot>
    </template>
  </Popper>
</template>

<style lang="scss">
:root {
  --popper-theme-background-color: theme('colors.gray.900');
  --popper-theme-background-color-hover: theme('colors.gray.900');
  --popper-theme-text-color: theme('colors.white');
  --popper-theme-border-width: 0px;
  --popper-theme-border-style: solid;
  --popper-theme-border-radius: 8px;
  --popper-theme-padding: 8px;
  --popper-theme-box-shadow: 0 6px 30px -6px rgba(0, 0, 0, 0.25);
}
</style>
